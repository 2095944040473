@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', cursive;
}

.nav-item a{
    color: white;
}
.nav-tabs .nav-link{
    border: none;
}

.nav-tabs .nav-link:hover{
    color: #c2c2c2;
}

.nav-tabs .nav-link.active{
    color: #8d8d8d;
    background-color: #d9ecf2;
}

.nav-tabs{
    border: none;
}

.content{
    background: #D9ECF2;
    padding: 2rem 1rem;
}

.react-table{
    background-color: #f3f3f3;
}

.pricing-icon{
    height: 50px;
}

.pricing-table{
    text-align: center;
}

.pricing-table img{
    height: 32px;
}

.rt-td{
    text-align: center;
}

.nav-wrapper{
    margin-top: auto;
    position: relative;
}

.nav-left{
    margin-right: auto;
}

.nav-right{
    margin-left: auto;
}

.form-signin{
    width: 100%;
    padding: 15px;
    margin: 0 auto;
}

.ReactTable .rt-th, .ReactTable .rt-td{
    font-size: 13px;
}

#container-circles{
    display: table;
}

.container{
    text-align: center;
}
